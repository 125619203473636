import React, {Suspense, useEffect, useState, useContext} from "react"
import { BlocksContext } from "../context/blocksContext"
// import { Main_screen } from './main_screen';
import { Menu } from './menu';
import { Header } from './header';
import { About_project } from './about_project';
import { Banner_action } from './banner_action';



export const Loader = () => {
    //const [loaded, setLoaded] = useState(2)

    

    const Galery = React.lazy(() => import("./galery"))
    const Presentation = React.lazy(() => import("./presentation"))
    // const Parking_action = React.lazy(() => import("./parking_action"))
    const Loc = React.lazy(() => import("./loc"))
    const Popups = React.lazy(() => import("./popups"))
    const Genplan = React.lazy(() => import("./genplan"))
    const Infra = React.lazy(() => import("./infra"))
    const Advantage = React.lazy(() => import("./advantage"))
    const Repair_apart = React.lazy(() => import("./repair_apart"))
    const Plan_price = React.lazy(() => import("./plan_price"))
    const Vacant_apart = React.lazy(() => import("./vacant_apart"))
    const How_buy = React.lazy(() => import("./how_buy"))
    const Parking = React.lazy(() => import("./parking"))
    const MonthActions = React.lazy(() => import("./monthActions"))
    const Kellers = React.lazy(() => import("./kellers"))
    const About_us = React.lazy(() => import("./about_us"))
    const Hod_str = React.lazy(() => import("./hod_str"))
    const FloorDemo = React.lazy(() => import("./floorDemo"))
    const Contacts = React.lazy(() => import("./contacts"))

    const Footer = React.lazy(() => import("./footer"))

    const blocksImports = {
        // 'main_screen': <Main_screen />,
        'menu': <Menu />,
        'header': <Header />,
        'banner_action': <Banner_action />,
        'about_project': <About_project />,
        'demo': <FloorDemo />,
        'popups': <Popups />,
        'presentation': <Presentation />,
        'galery': <Galery />,
        "month_actions" : <MonthActions />,
        // 'parking_action': <Parking_action />,
        'loc': <Loc />,
        'genplan': <Genplan />,
        'infra': <Infra />,
        'advantage': <Advantage />,
        'repair_apart': <Repair_apart />,
        'plan_price': <Plan_price />,
        'vacant_apart': <Vacant_apart />,
        'how_buy': <How_buy />,
        'parking': <Parking />,
        'kellers': <Kellers />,
        'about_us': <About_us />,
        'hod_str': <Hod_str />,
        'contacts': <Contacts />,
        'footer': <Footer />,
    }





    
    const blocks = ["header", "menu", "banner_action", "about_project", "demo", "popups", "presentation", "galery", "parking_action", "loc", "month_actions", "genplan", "infra", "advantage", "repair_apart", "plan_price", "vacant_apart", "how_buy", "parking", "kellers", "about_us", "hod_str", "contacts", "footer"] // "hod_str",

    const loaded = useContext(BlocksContext)

    const LoadBlock = (block) => {
        return  <Suspense  fallback={<div>Загрузка...</div>}>{blocksImports[block]}</Suspense>
    }

    const generateHtml = () =>{
        let toDraw = []
        for(let i=0;i<loaded.blocks; i++){
            if (i>2){
                toDraw.push(LoadBlock(blocks[i]))
            } else {
                toDraw.push(blocksImports[blocks[i]])
            }
           
        }
        return (
            <div className="blocks" data={loaded.menuClick?"true":""}>
                {toDraw.map((block) => block)}
            </div>
        )
    }
    const  handleScroll = (event) => {

        if (loaded.blocks < blocks.length){
            loaded.setBlocks(blocks.length)
            loaded.setPopup(true)
        } else {
           window.removeEventListener('scroll', handleScroll, true);
        }
    }
    useEffect(()=> {
        if (loaded.blocks<blocks.length){
            window.addEventListener('scroll', handleScroll, true);
        }
    })
    return generateHtml()
    
}