import { useEffect } from "react"


export const useSendForm = () => {

    const addError = (element) => {
        try {
            element.closest('.form_in').querySelector('label').classList.add('Mui-error')
        } catch (e) {

        }
    }
    const removeError = (element) => {
        try {
            element.closest('.form_in').querySelector('label').classList.remove('Mui-error')
        } catch (e) {

        }
    }
    const checkPhone = (element) => {
        if (element.value.indexOf('_') != -1 || element.value.length == 0) {
            addError(element)
            return false
        }
        removeError(element)
        return true
    }

    const checkEmail = (element) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(element.value).toLowerCase())) {
            addError(element)
            return false
        } else {
            return true
        }

    }
    const getUtms = (paths) => {
        let utm = {}
        if (paths.split('&').length > 0) {
            let params = paths.split('&')
            params.forEach((param) => {
                param = param.split('=')
                if (param[0] == "utm_medium" || param[0] == "utm_content" || param[0] == "utm_campaign" || param[0] == "utm_term" || param[0] == "utm_source") {
                    utm = { ...utm, [param[0]]: param[1] }
                }
            })
        }
        return utm
    }



    const showAlert = (data, celtype, sendCell) => {
        if (window.Ya != undefined && sendCell) {
            Object.keys(window.Ya._metrika.counters).forEach((counter) => {
                let metricks = parseInt(counter.split(':')[0])
                try {
                    ym(metricks, 'reachGoal', celtype);
                    ym(metricks, 'reachGoal', 'vse');
                } catch (e) { }
            })
        }
        try {
            gtag('event', 'send', {
                'event_category': 'forms',
                'event_label': celtype,
                'value': 1
            });
            gtag('event', 'send', {
                'event_category': 'forms',
                'event_label': 'vse',
                'value': 1
            });
        } catch (e) { }
        try {
            fbq('trackCustom', 'vse');
        } catch (e) { }
        try {
            VK.Goal('lead')
        } catch (e) { }

        fetch("fd_log/lrcnt_react.php", {})
            .then(data => data.ok && data.json())
            .then(response => {
                data = { ...data, ...response }
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(data)
                };
                fetch("main/ajax.php", requestOptions)
                    .then(data => data.ok)
            });
    }

    const getParam = (p) => {
        var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }


    const getYandexClientId = () => {
        window.site = {}
        site.clientID = 0
        try {
            setTimeout(() => {
                if (window.Ya_metrika) {
                let metriks = parseInt(Object.keys(window.Ya_metrika.counters)[0].split(':')[0])
                    ym(metriks, 'getClientID', function (clientID) {

                        site.clientID = parseInt(clientID)
                        console.log('yandex client id = ' + clientID)
                    })
                }
            }, 7000)
        } catch (e) {
            console.log('error getting yandex client id')
        }
    }

    const sendForm = (e) => {
        e.preventDefault()
        let flag = true
        let form = e.target.closest('form')
        let celtype = e.currentTarget.getAttribute('celtype')
        let name = form.querySelector('input[name="name"]') != null ? form.querySelector('input[name="name"]').value : ''
        let phone = form.querySelector('input[name="phone"]').value
        let url = window.location.toString().split("?")
        let email
        if (form.querySelector('input[name="email"]') != undefined) {
            email = form.querySelector('input[name="email"]').value
        }
        let utm = null
        if (url.length > 1) {
            utm = getUtms(url[1])
        }

        if (!checkPhone(form.querySelector('input[name="phone"]'))) {
            flag = false
        }

        if (form.querySelector('input[name="name"]').value == "") {
            flag = false
            addError(form.querySelector('input[name="name"]'))
        } else {
            removeError(form.querySelector('input[name="name"]'))
        }

        if (form.querySelector('input[name="email"]') != null && form.querySelector('input[name="email"]').value != "") {
            if (!checkEmail(form.querySelector('input[name="email"]'))) {
                flag = false
            }
        }
        let text = form.querySelector('input.text').value
        form.querySelectorAll('.dop-info').forEach((el) => {
            if (el.value.length != 0 && el.getAttribute('data') != null)
                text += ';' + el.getAttribute('data') + ':' + el.value
        })
        form.querySelectorAll('[name="dop-info"]').forEach((el) => {
            if (el.value.length != 0)
                text += '; ' + el.value
        })

        if (flag) {
            let senddata = { getCall: "Y", celtype: celtype, name: name, phone: phone, yclid: getParam('yclid'), gclid: getParam('gclid'), yandexClientId: site.clientID, roistat: getCookieByName("roistat_visit"), text: text, ...utm }
            if (email != undefined) {
                senddata = { ...senddata, email: email }
            }

            document.querySelectorAll('.popup_main').forEach((elem) => {
                elem.style.display = "none"
            })
            document.querySelector('.pu_vygoda').style.display = "none"
            document.querySelector('.popup_rgba').style.display = "block"
            document.querySelector('.pu_thx').style.display = "block"

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(senddata)
            };


            fetch("https://integrat.leadactiv.ru/lead/site", requestOptions)
                .then((response) => response.json())
                .then(data => {
                    showAlert(senddata, celtype, !data.spam)
                });

        }
    }

    useEffect(() => {
        getYandexClientId()
    }, [])

    return { sendForm }
}

const getCookieByName = (name) => {
    function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : "";
}