import React, { useContext, useEffect, useState } from 'react'
import { BlocksContext } from "../context/blocksContext"

export const About_project = () => {
    const [loadImage, setLoadImage] = useState(false)
    const loaded = useContext(BlocksContext)

    useEffect(()=>{
        console.log(loaded)
        setLoadImage(true)
    },[loaded.popup])


    return (
        <section className="anim about_project">
            <div className="cw_main">
                <div className="ap_right">
                    <div className="tm">
                        <span>О проекте​</span>
                        <div className="dec3"><img src={loadImage?"/images/dec3.svg":""} /></div>
                        <div className="dec4"><img src={loadImage?"/images/dec4.svg":""} /></div>
                    </div>
                    <img src={loadImage?"/images/ap_info_img.jpg":""} className="ap_info_img" />
                    <p>Новый жилой комплекс класса Комфорт расположен на высшей точке Первомайского района и отвечает самым амбициозным запросам современных городских жителей.</p>
                    <ul className="ap_list">
                        <li><span>4</span><p>25-этажных <br/> дома</p></li>
                        <li><span>6 500 м<sup>2</sup></span><p>Благоустроенного <br/> двора</p></li>
                        <li><span>1 900 м<sup>2</sup></span><p>Коммерческих <br/> помещений</p></li>
                        <li><span>371</span><p>Машиномест в <br/> подземном паркинге</p></li>
                        <li><span>157</span><p>Машиномест на <br/> гостевой парковке</p></li>
                        <li><span>420</span><p>Мест в детском саду, <br/> который проектирует застройщик</p></li>
                    </ul>
                    <img src={loadImage?"/images/ap_img.jpg":""} className="ap_img" />
                </div>
            </div>
		</section>
    )
}